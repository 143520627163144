import * as React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
// import { moreButton } from "./more-button.module.css"

const StyledMoreBtn = styled(Link)`
  background: ${({theme}) => theme.btn.default};
  color: ${({theme}) => theme.text};
  padding: 1em 1.5em;

  &:hover{
    color: ${({theme}) => theme.accent.primary.color};
  }
`

export function MoreButton({ className, ...props }) {
  return <StyledMoreBtn {...props} />
}
